import dayjs from "dayjs";

export const getUserTimezone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return timeZone;
};

export const shouldApplyGrayClass = (response: number, answer: number) => {
  return response !== answer && response !== 0;
};

export const changeDayjsLang = (
  date: dayjs.Dayjs,
  lang: string
): dayjs.Dayjs => {
  return lang === "no" ? date.locale("nb") : date.locale(lang);
};
