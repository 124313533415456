import { getWeekdayLocaleIndex } from "./getWeekdayLocaleIndex";
import type { Locale } from "date-fns";

type Weekday = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";

const A_B = -1;
const B_A = 1;

/**
 * Should sort weekdays according to current locale (starting from mon or sun)
 * @example
 * ['Mon', 'Thu', 'Wed'] -> ['Mon', 'Wed', 'Thu']
 * ['Mon', 'Sun'] -> ['Sun', 'Mon'] in locale where week starts from Sunday
 */
export function sortWeekdays(weekdays: Weekday[], locale?: Locale): Weekday[] {
  return [...weekdays].sort((a, b) => {
    const aIndex = getWeekdayLocaleIndex(a, locale);
    const bIndex = getWeekdayLocaleIndex(b, locale);

    return aIndex < bIndex ? A_B : B_A;
  });
}
