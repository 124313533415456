import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type { AlternativeFormattedType } from "constants/models";
import type { Dispatch, SetStateAction } from "react";

import CalendarSlots from "components/CalendarSlots";

import { ReactComponent as InfoSVG } from "assets/images/info.svg";

import dayjs from "utils/dayjs";
import { changeDayjsLang } from "utils";

import styles from "./index.module.scss";
import "./datepicker.css";

interface CalendarProps {
  currentDay: dayjs.Dayjs;
  alternativesFormatted: AlternativeFormattedType[];
  eventSelected: { alternativeIndex: number; id: number } | null;
  eventTimezone: string;
  onSlotClick: (id: number) => void;
  setCurrentDay: Dispatch<SetStateAction<dayjs.Dayjs>>;
}

const Calendar = ({
  currentDay,
  alternativesFormatted,
  eventSelected,
  eventTimezone,
  onSlotClick,
  setCurrentDay,
}: CalendarProps) => {
  const { t, i18n } = useTranslation();
  const [datepicker, setDatepicker] = useState(null);

  const dayNow = dayjs();
  const pickerRef = useRef<HTMLDivElement>(null);

  const getDaysArrayByMonth = (day: dayjs.Dayjs) => {
    const arrDays = [];
    const newDate = day.clone().subtract(1, "month");
    let daysInMonthPast = newDate.daysInMonth();
    let i = 1;

    while (i <= daysInMonthPast) {
      let current = newDate.clone().date(i);
      arrDays.push(current.clone());
      i++;
    }

    let daysInMonth = day.daysInMonth();
    i = 1;

    while (i <= daysInMonth) {
      let current = day.clone().date(i);
      arrDays.push(current.clone());
      i++;
    }

    return arrDays;
  };

  const schedule = getDaysArrayByMonth(alternativesFormatted[0].start);
  let parsedDisabledDate = [];

  for (let i = 0; i < schedule.length - 1; i++) {
    if (!schedule[i].isSame(alternativesFormatted[0].start)) {
      parsedDisabledDate.push(schedule[i]);
    } else {
      break;
    }
  }

  parsedDisabledDate = parsedDisabledDate.map((item) => {
    return item.format("MM/DD/YYYY");
  });

  const firstDayEnabled = alternativesFormatted[0].start.format("MM/DD/YYYY");
  const lastDayEnabled =
    alternativesFormatted[alternativesFormatted.length - 1].start.format(
      "MM/DD/YYYY"
    );

  const enabledDatesArray = [
    ...new Set(
      alternativesFormatted.map((day) => day.start.format("MM/DD/YYYY"))
    ),
  ];

  const markCurrentDate = () => {
    const weekNumbers = document.querySelectorAll(".weeks .week");

    if (
      dayjs(dayNow).format("MM/YYYY") === dayNow.format("MM/YYYY") ||
      dayjs(dayNow).clone().subtract(1, "month").format("MM/YYYY") ===
        dayNow.format("MM/YYYY") ||
      dayjs(dayNow).clone().add(1, "month").format("MM/YYYY") ===
        dayNow.format("MM/YYYY")
    ) {
      weekNumbers.forEach((week) => {
        week.classList.remove("active");
        if (+week.innerHTML === dayNow.locale("en").week()) {
          week.classList.add("active");
        }
      });
    } else {
      weekNumbers.forEach((week) => {
        week.classList.remove("active");
      });
    }
  };

  useEffect(() => {
    if (pickerRef?.current) {
      // @ts-ignore
      const datepicker = new window.Datepicker(pickerRef.current, {
        prevArrow:
          '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.2283 14.8093L11.847 14.1906C11.9935 14.0442 11.9935 13.8067 11.847 13.6603L6.20018 7.99998L11.847 2.33967C11.9935 2.19323 11.9935 1.9558 11.847 1.80933L11.2283 1.19061C11.0819 1.04417 10.8444 1.04417 10.698 1.19061L4.15377 7.73483C4.00734 7.88126 4.00734 8.1187 4.15377 8.26517L10.698 14.8094C10.8444 14.9558 11.0819 14.9558 11.2283 14.8093Z" fill="#999999"/></svg>',
        nextArrow:
          '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.77249 1.19066L4.15377 1.80935C4.00734 1.95579 4.00734 2.19322 4.15377 2.33969L9.80062 8L4.15377 13.6603C4.00734 13.8068 4.00734 14.0442 4.15377 14.1907L4.77249 14.8094C4.91893 14.9558 5.15637 14.9558 5.30284 14.8094L11.847 8.26519C11.9935 8.11875 11.9935 7.88132 11.847 7.73485L5.30284 1.19066C5.15637 1.04419 4.91893 1.04419 4.77249 1.19066Z" fill="#999999"/></svg>',
        calendarWeeks: true,
        weekStart: 1,
        datesDisabled: getExludedDays(),
        minDate: firstDayEnabled,
        maxDate: lastDayEnabled,
        language: i18n.language,
      });

      setTimeout(() => setDatepicker(datepicker));

      pickerRef.current.addEventListener("changeDate", (event) => {
        setTimeout(() => {
          // @ts-ignore
          const currentDayLocal = dayjs(event.detail.date);
          changeDayjsLang(currentDayLocal, i18n.language);
          setCurrentDay(currentDayLocal);
        });
      });
      pickerRef.current.addEventListener("changeMonth", () => {
        setTimeout(() => {
          markCurrentDate();
        });
      });
      markCurrentDate();
    }
  }, [pickerRef]);

  useEffect(() => {
    if (datepicker) {
      // @ts-ignore
      datepicker.setOptions({
        language: i18n.language,
      });
    }
  }, [i18n.language]);

  const getExludedDays = () => {
    const dayAmount = Math.abs(
      dayjs(firstDayEnabled).diff(lastDayEnabled, "day")
    );

    const daysArray = [];
    const cloneDate = dayjs(firstDayEnabled).clone();

    for (let i = 0; i < dayAmount; i++) {
      daysArray.push(cloneDate.add(i, "d").format("MM/DD/YYYY"));
    }

    const result = daysArray.filter(
      (date) => !enabledDatesArray.includes(date)
    );

    return result;
  };

  useEffect(() => {
    // @ts-ignore
    datepicker?.setDate(currentDay.format("MM/DD/YYYY"));
  }, [currentDay]);

  return (
    <div className={styles.calendar__wrapper}>
      <div className={styles.calendar__left}>
        <div
          id="picker"
          ref={pickerRef}
          data-date={currentDay.format("MM/DD/YYYY")}
        ></div>
        <div className={styles.calendar__info}>
          <InfoSVG />
          {t("available-dates")}
        </div>
      </div>
      <CalendarSlots
        currentDay={currentDay}
        alternativesFormatted={alternativesFormatted}
        eventSelected={eventSelected}
        eventTimezone={eventTimezone}
        onSlotClick={onSlotClick}
        enabledDatesArray={enabledDatesArray}
        setCurrentDay={setCurrentDay}
      />
    </div>
  );
};

export default Calendar;
