import React from "react";
import { useTranslation } from "react-i18next";

import { SelectedTimeslotType } from "constants/models";

import { ReactComponent as ConfirmedSVG } from "assets/images/confirmed.svg";

import { changeDayjsLang } from "utils";
import dayjs from "utils/dayjs";

import styles from "./index.module.scss";

interface ConfirmedProps {
  eventTitle: string;
  eventConfirmDate: SelectedTimeslotType;
}

const Confirmed = ({ eventTitle, eventConfirmDate }: ConfirmedProps) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const convertDate = () => {
    const dayjsDate = dayjs(eventConfirmDate.start * 1000);
    return changeDayjsLang(dayjsDate, lang).format("ddd, D. MMM YYYY");
  };

  const convertTime = () => {
    const timeStart = changeDayjsLang(
      dayjs(eventConfirmDate.start * 1000),
      lang
    );
    const timeEnd = changeDayjsLang(dayjs(eventConfirmDate.end * 1000), lang);

    return `${timeStart.format("HH:mm")} - ${timeEnd.format("HH:mm")}`;
  };

  return (
    <div className={styles.confirmed}>
      <h2 className={styles.confirmed__title}>
        <ConfirmedSVG />
        {t("confirmed")}
      </h2>
      <div className={styles.confirmed__descr}>
        {t("attendance-event", { eventTitle })}
      </div>
      <div className={styles.confirmed__date}>{convertDate()}</div>
      <div className={styles.confirmed__time}>{convertTime()}</div>
    </div>
  );
};

export default Confirmed;
