import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

interface TimezoneProps {
  timezone: string;
}

const Timezone = ({ timezone }: TimezoneProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.time__zone}>
        <div className={styles.time__zone_title}>{t("time-zone")}:</div>
        <div className={styles.time__zone_info_large}>
          {Intl.DateTimeFormat().resolvedOptions().timeZone}
        </div>
      </div>
      {timezone !== "UTC" && (
        <div className={styles.time__zone_organizer}>
          <div className={styles.time__zone_title}>
            {t("event-orginizer-timezone")}:
          </div>
          <div className={styles.time__zone_info}>{timezone}</div>
        </div>
      )}
    </div>
  );
};

export default Timezone;
