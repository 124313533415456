import i18n, { use } from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./translations/en.json";
import daTranslation from "./translations/da.json";
import noTranslation from "./translations/no.json";
import svTranslation from "./translations/sv.json";
import esTranslation from "./translations/es.json";

use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: enTranslation,
    },
    da: {
      translation: daTranslation,
    },
    no: {
      translation: noTranslation,
    },
    sv: {
      translation: svTranslation,
    },
    es: {
      translation: esTranslation,
    },
  },
});

export default i18n;
