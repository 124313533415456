import React, { useTransition } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import type { Dispatch, SetStateAction } from "react";
import type { AlternativeFormattedType } from "constants/models";

import { ReactComponent as BackArrowSVG } from "assets/images/backarrow.svg";
import { ReactComponent as CheckboxSVG } from "assets/images/small-checkbox.svg";

import dayjs from "utils/dayjs";
import { changeDayjsLang } from "utils";

import styles from "./index.module.scss";

interface CalendarSlotsProps {
  currentDay: dayjs.Dayjs;
  alternativesFormatted: AlternativeFormattedType[];
  eventSelected: { alternativeIndex: number; id: number } | null;
  eventTimezone: string;
  onSlotClick: (id: number) => void;
  enabledDatesArray: string[];
  setCurrentDay: Dispatch<SetStateAction<dayjs.Dayjs>>;
}

const CalendarSlots = ({
  currentDay,
  alternativesFormatted,
  eventSelected,
  eventTimezone,
  enabledDatesArray,
  setCurrentDay,
  onSlotClick,
}: CalendarSlotsProps) => {
  const { t, i18n } = useTranslation();

  const isMobile = window.innerWidth < 768;

  const currentDaySlots = alternativesFormatted.filter((slot) => {
    if (slot.start.format("YYYY-MM-DD") === currentDay.format("YYYY-MM-DD")) {
      return slot;
    }
  });

  const changeDay = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    type: string
  ) => {
    if (event.currentTarget.classList.contains(styles.disabled)) {
      return;
    }
    console.log("click");

    const currentIndex = enabledDatesArray.findIndex((date) =>
      dayjs(date).isSame(currentDay, "day")
    );

    let newIndex;
    if (type === "back") {
      newIndex = currentIndex - 1;
    } else {
      newIndex = currentIndex + 1;
    }

    if (newIndex >= 0 && newIndex < enabledDatesArray.length) {
      const newDate = dayjs(enabledDatesArray[newIndex]);
      setCurrentDay(newDate);
    }
  };

  const checkArrowDisabled = (type: string) => {
    if (type === "back") {
      return enabledDatesArray.indexOf(currentDay.format("MM/DD/YYYY")) === 0;
    } else {
      return (
        enabledDatesArray.indexOf(currentDay.format("MM/DD/YYYY")) ===
        enabledDatesArray.length - 1
      );
    }
  };

  return (
    <div className={styles.calendar__slots}>
      <div className={styles.slots__top}>
        <BackArrowSVG
          className={cn(styles.left__btn, {
            [styles.disabled]: checkArrowDisabled("back"),
          })}
          onClick={(event) => {
            changeDay(event, "back");
          }}
        />
        <div className={styles.info}>
          {dayjs(currentDay).format("ddd, D. ")}
          {dayjs(currentDay).format("MMMM")} {currentDay.format("YYYY")}
        </div>
        <BackArrowSVG
          className={cn(styles.right__btn, {
            [styles.disabled]: checkArrowDisabled("forward"),
          })}
          onClick={(event) => {
            changeDay(event, "forward");
          }}
        />
      </div>
      <div className={cn(styles.slots__bottom)}>
        <div
          className={styles.event__list}
          style={{
            gridTemplateColumns: `repeat(${
              isMobile || currentDaySlots.length < 3 ? "1" : "3"
            }, 1fr)`,
          }}
        >
          {currentDaySlots?.map((slot) => {
            return (
              <div
                className={cn(styles.event__item, {
                  [styles.active]: false,
                })}
                onClick={() => onSlotClick(slot.id)}
                key={slot.id}
              >
                <div className={styles.event__time}>
                  {slot.start.format("HH:mm")} - {slot.end.format("HH:mm")}
                </div>
                {eventSelected?.id !== slot.id ? (
                  <div className={styles.event__circle} />
                ) : (
                  <CheckboxSVG className={styles.event__checkbox} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarSlots;
