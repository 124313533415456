import axios from 'axios';

const HTTP = axios.create({
  baseURL: '/api/',
  // baseURL: "https://dev.rsvp.recman.services/api",
});

const request = (data: any) => {
  const url = `index.php`;
  return HTTP.post(url, data);
};

export { request };
