import React from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "pages/PageNotFound";
import Recaptcha from "pages/Recaptcha";
import Main from "pages/Main";

import { PATHS } from "constants/constants";

const App = () => {
  return (
    <Routes>
      <Route path={PATHS.index} element={<Main />} />
      <Route path={PATHS.recaptcha} element={<Recaptcha />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
