import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import type { Dispatch, SetStateAction } from "react";
import type { EventDataType, SelectedTimeslotType } from "constants/models";

import Timezone from "components/Timezone";
import RegisterForm from "components/RegisterForm";
import Calendar from "components/Calendar";
import Button from "components/Button";
import NoAlternatives from "components/NoAlternatives";

import { ReactComponent as CalendarSVG } from "assets/images/calendar.svg";
import { ReactComponent as View1SVG } from "assets/images/view1.svg";
import { ReactComponent as View2SVG } from "assets/images/view2.svg";
import { ReactComponent as View3SVG } from "assets/images/view3.svg";
import { ReactComponent as ArrowSVG } from "assets/images/arrow.svg";
import { ReactComponent as DoubleArrowSVG } from "assets/images/double-arrow.svg";
import { ReactComponent as CheckboxSVG } from "assets/images/checkbox.svg";
import { ReactComponent as BackArrowSVG } from "assets/images/backarrow.svg";
import { ReactComponent as NoTimeSVG } from "assets/images/notime.svg";
import { ReactComponent as NoSlotsSVG } from "assets/images/no_slots.svg";

import { viewTypes, SCOPES } from "constants/constants";
import type {
  AlternativeType,
  EventAttendeetype,
  AlternativeFormattedType,
} from "constants/models";

import { useClickAway } from "hooks/useClickAway";
import { request } from "api/APIService";
import { changeDayjsLang } from "utils/index";
import dayjs from "utils/dayjs";

import styles from "./index.module.scss";

interface SelectEventProps {
  eventTimezone: string;
  alternatives: AlternativeType[];
  eventAttendee?: EventAttendeetype;
  eventKey: string;
  eventDuration: number;
  fetchData: () => Promise<void>;
  setEventData: Dispatch<SetStateAction<EventDataType | null>>;
}

const SelectEvent = ({
  alternatives,
  eventTimezone,
  eventAttendee,
  eventKey,
  fetchData,
  eventDuration,
  setEventData,
}: SelectEventProps) => {
  const { t, i18n } = useTranslation();
  const dayNow = dayjs();

  const dropdownRef = useRef(null);
  const weekTableRef = useRef<HTMLDivElement>(null);

  useClickAway(dropdownRef, () => setListDropdownShow(false));

  const isMobile = window.innerWidth < 852;

  const [attendeeIsExist, setAttendeeIsExist] = useState(false);
  const [viewTypeSelected, setViewTypeSelected] = useState(0);
  const [listDropdownShow, setListDropdownShow] = useState(false);
  const [eventSelected, setEventSelected] = useState<{
    alternativeIndex: number;
    id: number;
  } | null>(null);

  const [currentDay, setCurrentDay] = useState<dayjs.Dayjs>(
    dayjs(alternatives[0]?.start * 1000)
  );
  const [currentWeek, setCurrentWeek] = useState<number | null>(
    currentDay?.isoWeek() || null
  );
  const [currentYear, setCurrentYear] = useState<number | null>(
    currentDay?.year() || null
  );
  const [currentWeekDays, setCurrentWeekDays] = useState<
    { day: dayjs.Dayjs; active?: boolean }[]
  >([]);
  const rowsCount = (60 / eventDuration) * 24;

  const [weekTableExist, setWeekTableExist] = useState(true);

  const chooseOption = (index: number) => {
    setViewTypeSelected(index);
    setEventSelected(null);

    const firstSlot = dayjs(alternatives[0].start * 1000);

    setCurrentDay(firstSlot);
    setCurrentWeek(firstSlot.isoWeek());
    setCurrentYear(firstSlot.year());

    setListDropdownShow(false);
  };

  const convertSlotDate = (date: number, mode: string): string => {
    const dayjsTimeStart = changeDayjsLang(dayjs(date * 1000), i18n.language);

    if (mode === "long") {
      return dayjsTimeStart
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("dddd, D. MMMM");
    } else {
      return dayjsTimeStart
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("ddd, D. MMM");
    }
  };

  const convertSlotTime = (timeStart: number, timeEnd: number) => {
    const dayjsTimeStart = dayjs(timeStart * 1000);
    const dayjsTimeEnd = dayjs(timeEnd * 1000);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return `${dayjsTimeStart.tz(timeZone).format("HH:mm")} - ${dayjsTimeEnd
      .tz(timeZone)
      .format("HH:mm")}`;
  };

  const confirmClick = async (userDate: any, isRegister?: boolean) => {
    if (!eventSelected) return;
    const data = {
      token: eventKey,
      scope: SCOPES.enterAppointmentEventDecision,
      timeslotId: eventSelected?.id,
      displayName: userDate.name || eventAttendee?.displayName,
      email: userDate.email || eventAttendee?.email,
      comment: userDate.comment || null,
    };

    try {
      const response = await request(data);

      if (response.data.success) {
        const selectedAlternative = alternatives.find(
          (alternative) => alternative.id === eventSelected.id
        ) as SelectedTimeslotType;
        isRegister
          ? setEventData((prev) => {
              if (prev) {
                return {
                  ...prev,
                  data: { ...prev.data, selectedTimeslot: selectedAlternative },
                };
              } else {
                return null;
              }
            })
          : fetchData();
      } else {
        setAttendeeIsExist(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const alternativesWithWeek = alternatives.map((slot) => {
    const slotStartDay = dayjs(slot.start * 1000);
    const slotWeek = slotStartDay.isoWeek();
    return { ...slot, week: slotWeek };
  });

  let alternativesFormatted: AlternativeFormattedType[] =
    alternativesWithWeek.map((item) => {
      return {
        ...item,
        start: dayjs(item.start * 1000) as dayjs.Dayjs,
        end: dayjs(item.end * 1000) as dayjs.Dayjs,
        gridPosition: null,
        active: false,
      };
    });

  const checkNextPreviousEventExist = (offset: string) => {
    const firstSlotDate = alternativesFormatted[0].start;
    const lastSlotDate =
      alternativesFormatted[alternativesFormatted.length - 1].start;

    let newDate = currentDay.clone();
    newDate =
      offset === "back"
        ? newDate.subtract(1, "week")
        : newDate.add(1, "week").startOf("isoWeek");

    if (offset === "back") {
      return (
        !!newDate?.isBefore(firstSlotDate) &&
        !newDate.isSame(firstSlotDate, "isoWeek")
      );
    } else {
      return !!newDate.isAfter(lastSlotDate);
    }
  };

  const getAllWeekDays = (rowsCount: number, duration: number) => {
    const weekDays = [];
    let weekStart = currentDay.clone().startOf("isoWeek");

    for (let i = 0; i < rowsCount; i++) {
      let weekStart2 = weekStart.clone();
      for (let j = 0; j < 7; j++) {
        weekDays.push(weekStart2);
        weekStart2 = weekStart2.add(1, "day");
      }
      weekStart = weekStart.add(duration, "minutes");
    }

    return weekDays;
  };

  const allWeekDays = getAllWeekDays(rowsCount, eventDuration);

  function addGridPosition(
    allWeekDays: dayjs.Dayjs[],
    alternatives: AlternativeFormattedType[]
  ) {
    const result = [...alternatives];

    for (let i = 0; i < allWeekDays.length; i++)
      result.forEach((alternative) => {
        if (
          (alternative.start.isAfter(allWeekDays[i]) ||
            alternative.start.isSame(allWeekDays[i])) &&
          alternative.start.isBefore(allWeekDays[i].clone().add(1, "hours"))
        ) {
          alternative.gridPosition = i;
        }
      });

    return result;
  }

  addGridPosition(allWeekDays as dayjs.Dayjs[], alternativesFormatted);

  const getWeekDays = (day: dayjs.Dayjs) => {
    alternativesFormatted?.forEach((item) => {
      item.gridPosition = null;
    });
    setCurrentWeekDays([]);

    const weekStart = changeDayjsLang(day, i18n.language)
      .clone()
      .startOf("isoWeek");
    const updatedCurrentWeekDays: { day: dayjs.Dayjs; active?: boolean }[] = [];
    for (let i = 0; i < 7; i++) {
      const day = changeDayjsLang(dayjs(weekStart), i18n.language);
      updatedCurrentWeekDays.push({ day: day.add(i, "d") });
    }

    updatedCurrentWeekDays?.forEach((day) => {
      alternativesFormatted?.forEach((slot) => {
        if (day.day.isSame(slot.start, "day")) {
          day.active = true;
        }
      });
    });
    setCurrentWeekDays(updatedCurrentWeekDays);
  };

  useEffect(() => {
    if (!isMobile) {
      getWeekDays(currentDay);
      removeWeekTableBorder();
    }
  }, [currentDay]);

  const changeWeek = (offset: string) => {
    if (offset === "back") {
      if (checkNextPreviousEventExist("back")) {
        return;
      }
    } else {
      if (checkNextPreviousEventExist("next")) {
        return;
      }
    }
    setWeekTableExist(false);

    const updatedCurrentDay =
      offset === "back"
        ? currentDay.subtract(1, "week")
        : currentDay.add(1, "week");
    setCurrentDay(changeDayjsLang(updatedCurrentDay, i18n.language));
    setCurrentWeek(updatedCurrentDay.isoWeek());
    setCurrentYear(updatedCurrentDay.year());
  };

  useEffect(() => {
    setCurrentDay((prev) =>
      dayjs(prev).locale(i18n.language === "no" ? "nb" : i18n.language)
    );
  }, [i18n.language]);

  const removeWeekTableBorder = () => {
    const weekTable = weekTableRef.current;
    const weekTableHeight = weekTable?.offsetHeight;

    if (weekTableHeight !== undefined && weekTableHeight <= 1) {
      setWeekTableExist(false);
      weekTable?.classList.add(styles.week__table_noborder);
    } else {
      setWeekTableExist(true);
      weekTable?.classList.remove(styles.week__table_noborder);
    }
  };

  const onSlotClick = (id: number) => {
    if (eventSelected?.id === id) {
      setEventSelected(null);
    } else {
      const slotIndex = alternativesFormatted.findIndex((el) => el.id === id);

      setEventSelected({ alternativeIndex: slotIndex, id: id });
    }
  };

  const getSlotsSpecificDay = () => {
    if (!currentDay) return;

    return alternativesFormatted.filter((slot) => {
      return (
        slot.start.isSame(currentDay, "day") &&
        slot.start.format("DD") === currentDay.format("DD")
      );
    });
  };

  const slotsSpecificDay = getSlotsSpecificDay();

  const changeWeekWeek = (offset: string) => {
    if (!currentDay) return;

    if (offset === "back") {
      if (checkNextPreviousEventExist("back")) {
        return;
      }
    } else {
      if (checkNextPreviousEventExist("next")) {
        return;
      }
    }

    const newCurrentDay =
      offset === "back"
        ? currentDay.clone().subtract(1, "week")
        : currentDay.clone().add(1, "week");

    setCurrentDay(newCurrentDay);
  };

  const checkNextPreviousDayEventExist = (offset: string) => {
    const firstSlotDate = alternativesFormatted[0].start;
    const lastSlotDate =
      alternativesFormatted[alternativesFormatted.length - 1].start;

    let newDate = currentDay.clone();
    newDate =
      offset === "back" ? newDate.subtract(1, "day") : newDate.add(1, "day");

    if (offset === "back") {
      return !!newDate.endOf("day").isBefore(firstSlotDate);
    } else {
      return newDate.startOf("day").isAfter(lastSlotDate) !== false;
    }
  };

  const changeWeekDay = (offset: string) => {
    if (offset === "back") {
      if (checkNextPreviousDayEventExist("back")) {
        return;
      }
    } else {
      if (checkNextPreviousDayEventExist("next")) {
        return;
      }
    }

    const newCurrentDay =
      offset === "back"
        ? currentDay.clone().subtract(1, "day")
        : currentDay.clone().add(1, "day");

    setCurrentDay(newCurrentDay);
  };

  return (
    <div className={styles.select__event}>
      <div className={styles.select__event_header}>
        <div className={styles.title}>
          <CalendarSVG />
          {t("date-and-time")}
        </div>
      </div>
      {!!alternatives.length && (
        <div>
          <h2
            className={cn(styles.select__event_title, {
              [styles.title__margin_bottom]: viewTypeSelected === 1,
            })}
          >
            {t("select-event-time")}
          </h2>
          <div className={styles.event__topline}>
            <div className={styles.event__topline_left} ref={dropdownRef}>
              <div className={styles.view__type_title}>{t("view-type")}</div>
              <div
                className={styles.view__type_wrap}
                onClick={() => setListDropdownShow((prev) => !prev)}
              >
                <div className={styles.view__type}>
                  {viewTypeSelected === 0 && <View1SVG />}
                  {viewTypeSelected === 1 && <View2SVG />}
                  {viewTypeSelected === 2 && <View3SVG />}
                  {t(viewTypes[viewTypeSelected].title)}
                </div>
                <ArrowSVG className={styles.arrow} />
              </div>
              {listDropdownShow && (
                <div className={styles.list__dropdown}>
                  {viewTypes.map((item, index) => {
                    const SvgComponent = {
                      View1SVG,
                      View2SVG,
                      View3SVG,
                    }[item.img];
                    return (
                      <div
                        className={styles.list__item}
                        key={item.title}
                        onClick={(e) => {
                          e.stopPropagation();
                          chooseOption(index);
                        }}
                      >
                        {SvgComponent && <SvgComponent />}
                        {t(item.title)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className={styles.event__topline_right}>
              <Timezone timezone={eventTimezone} />
            </div>
          </div>
          {viewTypeSelected === 0 && (
            <div className={styles.event__list}>
              {alternatives.map((event, index) => (
                <div
                  key={event.id}
                  className={cn(styles.event__item, {
                    [styles.selected]:
                      eventSelected?.alternativeIndex === index,
                  })}
                  onClick={() =>
                    setEventSelected({ alternativeIndex: index, id: event.id })
                  }
                >
                  <div className={styles.event__date}>
                    {convertSlotDate(event.start, "long")}
                  </div>
                  <div className={styles.event__time}>
                    {convertSlotTime(event.start, event.end)}
                  </div>

                  {eventSelected?.alternativeIndex !== index ? (
                    <div className={styles.event__circle}></div>
                  ) : (
                    <CheckboxSVG className={styles.event__checkbox} />
                  )}
                </div>
              ))}
            </div>
          )}
          {viewTypeSelected === 1 && !isMobile && (
            <div className={styles.event__week}>
              <div className={styles.week__selector}>
                <BackArrowSVG
                  className={cn(styles.week__back, {
                    [styles.week__cursor_hidden]:
                      checkNextPreviousEventExist("back"),
                  })}
                  onClick={() => changeWeek("back")}
                />
                <div className={styles.week__number}>
                  {`${t("Week")} ${currentWeek}, ${currentYear} `}
                </div>
                <BackArrowSVG
                  className={cn(styles.week__forward, {
                    [styles.week__cursor_hidden]:
                      checkNextPreviousEventExist("next"),
                  })}
                  onClick={() => changeWeek("forward")}
                />
              </div>
              <div className={styles.week__days}>
                {currentWeekDays?.map((day, index) => {
                  return (
                    <div
                      className={cn(styles.week__days_day, {
                        [styles.dayNow]:
                          day.day.format("D MMM YYYY") ===
                          dayNow.format("D MMM YYYY"),
                      })}
                      key={index}
                    >
                      <div
                        className={cn(styles.day__top, {
                          [styles.day__top__red]: index === 5 || index === 6,
                        })}
                      >
                        {day.day.format("ddd")}
                      </div>
                      <div
                        className={cn(styles.day__bottom, {
                          [styles.active]: day.active,
                        })}
                      >
                        {day.day.format("D. MMM")}
                      </div>
                    </div>
                  );
                })}
              </div>
              {!!currentWeekDays.length && (
                <div className={styles.week__table} ref={weekTableRef}>
                  {Array.from(Array(rowsCount).keys())?.map((rowIndex) => {
                    return (
                      <div className={styles.table__row} key={rowIndex}>
                        {currentWeekDays?.map((cell, dayIndex) => {
                          return (
                            <div
                              key={dayIndex}
                              className={cn(styles.table__slot, {
                                [styles.dayNow]:
                                  changeDayjsLang(
                                    cell.day,
                                    i18n.language
                                  ).format("D MMM YYYY") ===
                                  changeDayjsLang(dayNow, i18n.language).format(
                                    "D MMM YYYY"
                                  ),
                              })}
                            >
                              <div className={styles.slot__inner}>
                                {alternativesFormatted?.map((item, index) => {
                                  if (
                                    currentWeek === item.week &&
                                    item.gridPosition ===
                                      rowIndex + dayIndex + 6 * rowIndex
                                  ) {
                                    return (
                                      <div
                                        key={item.id}
                                        className={cn(styles.slot__inner_body, {
                                          [styles.active]:
                                            eventSelected !== null &&
                                            item.id ===
                                              alternativesFormatted[
                                                eventSelected.alternativeIndex
                                              ]?.id,
                                        })}
                                        onClick={(e) => onSlotClick(item?.id)}
                                      >
                                        {item.start.format("HH:mm")}
                                        {" - "}
                                        {item.end.format("HH:mm")}
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}

              <div
                className={cn(styles.week__noslots, {
                  [styles.show]: !weekTableExist,
                })}
              >
                <div className={styles.no__slots_top}>
                  <NoSlotsSVG />
                </div>
                <div className={styles.no__slots_bottom}>
                  {t("no-time-week")}
                </div>
              </div>
            </div>
          )}
          {viewTypeSelected === 1 && isMobile && currentDay && (
            <div className={styles.event__day}>
              <div className={styles.event__day__selector}>
                <DoubleArrowSVG
                  className={cn(styles.day__week_back, {
                    [styles.week__cursor_hidden]:
                      checkNextPreviousEventExist("back"),
                  })}
                  onClick={() => {
                    changeWeekWeek("back");
                  }}
                />
                <BackArrowSVG
                  onClick={() => changeWeekDay("back")}
                  className={cn(styles.day__day_back, {
                    [styles.week__cursor_hidden]:
                      checkNextPreviousDayEventExist("back"),
                  })}
                />
                <div className={styles.day__info}>
                  <div className={styles.day__info_top}>
                    {currentDay.format("ddd")}
                  </div>
                  <div className={styles.day__info_bottom}>
                    {currentDay.format("D. MMM")}
                  </div>
                </div>
                <BackArrowSVG
                  onClick={() => changeWeekDay("forward")}
                  className={cn(styles.day__day_forward, {
                    [styles.week__cursor_hidden]:
                      checkNextPreviousDayEventExist("forward"),
                  })}
                />
                <DoubleArrowSVG
                  className={cn(styles.day__week_forward, {
                    [styles.week__cursor_hidden]:
                      checkNextPreviousEventExist("next"),
                  })}
                  onClick={() => changeWeekWeek("forward")}
                />
              </div>
              {currentDay && (
                <div className={styles.day__table}>
                  {slotsSpecificDay?.map((slot) => {
                    return (
                      <div
                        onClick={() => onSlotClick(slot.id)}
                        key={slot.id}
                        className={cn(styles.day__table_slot, {
                          [styles.active]: eventSelected?.id === slot.id,
                        })}
                      >
                        {slot.start.format("HH:mm")}
                        {" - "}
                        {slot.end.format("HH:mm")}
                      </div>
                    );
                  })}
                </div>
              )}
              {!slotsSpecificDay?.length && (
                <div className={styles.event__no_slots}>
                  <NoSlotsSVG className={styles.no__slots_top} />
                  <div className={styles.no__slots_bottom}>
                    {t("no-time-date")}
                  </div>
                </div>
              )}
            </div>
          )}
          {viewTypeSelected === 2 && (
            <div className={styles.event__month}>
              <Calendar
                currentDay={currentDay as dayjs.Dayjs}
                alternativesFormatted={alternativesFormatted}
                eventSelected={eventSelected}
                eventTimezone={eventTimezone}
                onSlotClick={onSlotClick}
                setCurrentDay={setCurrentDay}
              />
            </div>
          )}
        </div>
      )}
      {!alternatives.length && <NoAlternatives />}

      {eventSelected !== null &&
        eventSelected.alternativeIndex >= 0 &&
        eventAttendee && (
          <div className={styles.event__confirm}>
            <div className={styles.event__confirm_title}>
              {t("confirm-event")}
              <span>
                {convertSlotDate(
                  alternatives[eventSelected.alternativeIndex].start,
                  "short"
                )}{" "}
                {convertSlotTime(
                  alternatives[eventSelected.alternativeIndex].start,
                  alternatives[eventSelected.alternativeIndex].end
                )}
              </span>
              <span className={styles.event__confirm_timezone}>
                ({Intl.DateTimeFormat().resolvedOptions().timeZone})
              </span>
              {t("to-schedule")}
            </div>
            <div className={styles.btn__wrap}>
              <Button className={styles.btn} onClick={confirmClick} green>
                {t("confirm")}
              </Button>
            </div>
          </div>
        )}
      {eventSelected !== null &&
        eventSelected.alternativeIndex >= 0 &&
        !eventAttendee && (
          <div
            className={cn({
              [styles.event__confirm_list]: viewTypeSelected === 0,
            })}
          >
            <div className={styles.event__confirm_title}>
              {t("register-confirm-event")}
              <span>
                {convertSlotDate(
                  alternatives[eventSelected.alternativeIndex].start,
                  "short"
                )}{" "}
                {convertSlotTime(
                  alternatives[eventSelected.alternativeIndex].start,
                  alternatives[eventSelected.alternativeIndex].end
                )}
              </span>
            </div>
            <RegisterForm
              confirmClick={confirmClick}
              attendeeIsExist={attendeeIsExist}
            />
          </div>
        )}
    </div>
  );
};

export default SelectEvent;
