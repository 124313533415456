import React, { useRef, useEffect, useState } from "react";

import DropDown from "components/DropDown";
import Tooltip from "components/Tooltip";

import { ReactComponent as DefaultLogo } from "assets/images/logo.svg";

import styles from "./index.module.scss";

interface HeaderProps {
  logo?: string;
  title: string;
}

const Header = ({ logo, title }: HeaderProps) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    if (titleRef) {
      const titleWrapperElement = titleRef.current;

      const handleTooltip = () => {
        if (titleWrapperElement) {
          setTimeout(() => {
            setIsTooltipVisible(
              titleWrapperElement.scrollWidth > titleWrapperElement.clientWidth
            );
          }, 100);
        }
      };
      handleTooltip();

      window.addEventListener("resize", handleTooltip);

      return () => {
        window.removeEventListener("resize", handleTooltip);
      };
    }
  }, [titleRef.current]);

  return (
    <header className={styles.header}>
      <div className={styles.header__inner}>
        {logo ? (
          <img className={styles.header__logo} src={logo} alt="logo" />
        ) : (
          <DefaultLogo className={styles.header__logo} />
        )}

        <h2 className={styles.header__title} ref={titleRef}>
          {title}
        </h2>
        {isTooltipVisible && (
          <Tooltip text={title} classname={styles.tooltip} />
        )}

        <div className={styles.dropdown}>
          <DropDown />
        </div>
      </div>
    </header>
  );
};

export default Header;
