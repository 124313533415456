import React from "react";
import { useTranslation } from "react-i18next";

import DropDown from "components/DropDown";

import BGImage from "assets/images/recaptcha_bg.jpg";
import BGImageMobile from "assets/images/recaptcha_bg_mobile.jpg";

import styles from "./index.module.scss";

const PageNotFound = ({ staticKey }: { staticKey?: string }) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 800;

  return (
    <div className={styles.closed__container}>
      <div className={styles.container__top}>
        <img src={isMobile ? BGImageMobile : BGImage} alt="" />
      </div>
      <div className={styles.container__bottom}>
        <div className={styles.language__picker}>
          <DropDown />
        </div>
        <div className={styles.bottom__text_container}>
          <div className={styles.closed__title_top}>Error 404</div>
          <div className={styles.closed__divider}></div>
          <h1 className={styles.closed__title}>{t("event-not-found")}</h1>
          <h2 className={styles.closed__text}>{t("page-does-not-exist")}</h2>
          {staticKey && (
            <div className={styles.rsvp__key}>KEY: {staticKey}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
