import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import cn from "classnames";

import Button from "components/Button";

import { request } from "api/APIService";

import { RecurrenceOptionsType } from "constants/models";
import { SCOPES, UserAnswers } from "constants/constants";

import { ReactComponent as CalendarSVG } from "assets/images/calendar.svg";
import { ReactComponent as InfoSVG } from "assets/images/info.svg";
import { ReactComponent as CheckmarkSVG } from "assets/images/checkmark.svg";
import { ReactComponent as CrossSVG } from "assets/images/cross.svg";
import { ReactComponent as MaybeSVG } from "assets/images/maybe.svg";
import { ReactComponent as WaitingSVG } from "assets/images/waiting.svg";

import { getUserTimezone, shouldApplyGrayClass } from "utils";
import dayjs from "utils/dayjs";
import { getCustomRecurrenceString } from "helpers/date/getCustomRecurrenceString";

import styles from "./index.module.scss";

interface DateAndTimeProps {
  start: number;
  end: number;
  timeZone: string;
  currentResponse: number;
  recurrence: number;
  recurrenceOptions?: RecurrenceOptionsType;
  eventKey: string;
  fetchData: () => Promise<void>;
}

const DateAndTime = ({
  start,
  end,
  timeZone,
  currentResponse,
  recurrence,
  recurrenceOptions,
  eventKey,
  fetchData,
}: DateAndTimeProps) => {
  const { t } = useTranslation();

  const [localCurrentUserResponseStatus, setLocalCurrentUserResponseStatus] =
    useState(currentResponse);

  const eventStart = dayjs(start * 1000);
  const eventEnd = dayjs(end * 1000);

  const currentTime = dayjs();
  const eventStatus =
    currentTime >= eventStart && currentTime < eventEnd
      ? 1
      : currentTime >= eventEnd
      ? 2
      : 0;

  let difference = dayjs(currentTime).diff(eventStart, "minutes");
  const progress10MinutesLockdown = difference < 10;

  const userTimezone = getUserTimezone();

  const btnClickHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    attendeeDecision: number
  ) => {
    const target = e.target as HTMLElement;
    const buttonsArr = Array.prototype.slice.call(target.parentNode?.children);

    buttonsArr.forEach((button) => {
      if (e.target !== button) {
        button.classList.add(styles.btn__gray);
      } else {
        button.classList.remove(styles.btn__gray);
      }
    });

    setLocalCurrentUserResponseStatus(attendeeDecision);

    const data = {
      attendeeToken: eventKey,
      scope: SCOPES.enterEventDecision,
      attendeeDecision,
    };

    try {
      const res = await request(data);
      if (res.data.success) {
        fetchData();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.date__time}>
      <div className={styles.date__time_inner}>
        <div className={styles.date__time_wrap}>
          <div className={styles.date__time_top}>
            <div className={styles.calendar}>
              <div className={styles.calendar__top}>
                {dayjs(eventStart).format("MMM")}
              </div>
              <div className={styles.calendar__bottom}>
                <div className={styles.bottom__day}>
                  {dayjs(eventStart).format("DD")}
                </div>
                <div className={styles.bottom__weekday}>
                  {dayjs(eventStart).format("ddd")}
                </div>
              </div>
            </div>
            <div className={styles.date}>
              <div className={styles.date__title}>
                <CalendarSVG />
                {t("date-and-time")}
              </div>
              {!!recurrence && (
                <div className={styles.date__repeats}>{t("repeats")}</div>
              )}
              <div className={styles.date__date}>
                {!!recurrence && recurrenceOptions && (
                  <span>
                    {getCustomRecurrenceString(
                      recurrence,
                      recurrenceOptions,
                      dayjs(start * 1000),
                      t
                    )}
                  </span>
                )}

                <div>
                  {eventEnd.date() - eventStart.date() > 1 && (
                    <div>
                      <div className={styles.date__from}>
                        <div className={styles.date__start_label}>
                          {t("from")}
                        </div>
                        <div className={styles.date__event_value}>
                          {eventStart.date()}.{" "}
                          <span className={styles.capitalize}>
                            {dayjs(eventStart).format("MMMM")}
                          </span>
                          {eventStart.year()}
                        </div>
                        <div className={styles.date__event_bottom}>
                          <span className={styles.date__event_time}>
                            {dayjs(eventStart).format("HH:mm")}
                          </span>
                        </div>
                      </div>
                      <div className={styles.date__to}>
                        <div className={styles.date__start_label}>
                          {t("to")}
                        </div>
                        <div className={styles.date__event_value}>
                          {eventEnd.date()}.{" "}
                          <span className={styles.capitalize}>
                            {eventEnd.format("MMMM")}
                          </span>{" "}
                          {eventEnd.year()}
                        </div>
                        <div className={styles.date__event_bottom}>
                          <span className={styles.date__event_time}>
                            {dayjs(eventEnd).format("HH:mm")}
                          </span>
                          <span className={styles.date__t_offset}>
                            ({userTimezone})
                          </span>
                          {timeZone !== "UTC" && (
                            <div className={styles.date__timezone}>
                              {t("event-orginizer-timezone")}:{" "}
                              <strong>{timeZone}</strong>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!(eventEnd.date() - eventStart.date() > 1) && (
                    <div>
                      {!recurrence && (
                        <span>
                          {eventStart.date()}.{" "}
                          <span className={styles.capitalize}>
                            {eventStart.format("MMMM")}
                          </span>{" "}
                          {eventStart.year()}
                        </span>
                      )}

                      <div className={styles.date__t}>
                        {` ${eventStart.format("HH:mm")} - ${eventEnd.format(
                          "HH:mm"
                        )}`}
                        <span className={styles.date__t_offset}>
                          {" "}
                          ({userTimezone})
                        </span>
                        {timeZone !== "UTC" && (
                          <div className={styles.date__timezone}>
                            {`${t("event-orginizer-timezone")}: `}
                            <strong>{timeZone}</strong>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={cn(styles.date__time_bottom, {
              [styles.date__time_bottom_progress]: eventStatus !== 0,
            })}
          >
            {eventStatus !== 0 && (
              <div className={styles.event__status}>
                <div className={styles.status__title}>
                  {eventStatus === 1 ? (
                    <div className={styles.status__title_inprogress}>
                      <InfoSVG />
                      <span>{t("event-in-progress")}</span>
                    </div>
                  ) : (
                    <div className={styles.status__title_finished}>
                      <InfoSVG />
                      <span>{t("event-finished")}</span>
                    </div>
                  )}
                </div>
                {!progress10MinutesLockdown && (
                  <div className={styles.user__status}>
                    {localCurrentUserResponseStatus === 3 && (
                      <div
                        className={cn(styles.status, styles.status__attending)}
                      >
                        <CheckmarkSVG />
                        {t("attending")}
                      </div>
                    )}
                    {localCurrentUserResponseStatus === 1 && (
                      <div
                        className={cn(
                          styles.status,
                          styles.status__notattending
                        )}
                      >
                        <CrossSVG />
                        {t("not-attending")}
                      </div>
                    )}
                    {localCurrentUserResponseStatus === 2 && (
                      <div className={cn(styles.status, styles.status__maybe)}>
                        <MaybeSVG />
                        {t("maybe-attending")}
                      </div>
                    )}

                    {localCurrentUserResponseStatus === 0 && (
                      <div
                        className={cn(styles.status, styles.status__noresponse)}
                      >
                        <WaitingSVG />
                        {t("no-response")}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {(eventStatus === 0 || progress10MinutesLockdown) && (
              <div className={styles.buttons}>
                <div className={styles.bottom__title}>{t("going")}</div>
                <div className={styles.bottom__buttons}>
                  <Button
                    onClick={(e) => btnClickHandler(e, UserAnswers.Yes)}
                    green
                    className={styles.btn}
                    gray={shouldApplyGrayClass(
                      localCurrentUserResponseStatus,
                      UserAnswers.Yes
                    )}
                  >
                    <CheckmarkSVG />
                    {t("yes")}
                  </Button>

                  <Button
                    className={styles.btn}
                    onClick={(e) => btnClickHandler(e, UserAnswers.No)}
                    red
                    gray={shouldApplyGrayClass(
                      localCurrentUserResponseStatus,
                      UserAnswers.No
                    )}
                  >
                    <CrossSVG />
                    {t("no")}
                  </Button>
                  <Button
                    className={styles.btn}
                    onClick={(e) => btnClickHandler(e, UserAnswers.Maybe)}
                    orange
                    gray={shouldApplyGrayClass(
                      localCurrentUserResponseStatus,
                      UserAnswers.Maybe
                    )}
                  >
                    <MaybeSVG />
                    {t("maybe")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateAndTime;
