import React, { useState, useRef } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

import { ReactComponent as ArrowSVG } from "assets/images/arrow.svg";

import { useClickAway } from "hooks/useClickAway";

import dayjs from "utils/dayjs";

import { languages, supportedLang, languageImages } from "./constants";

const DropDown = () => {
  const { t, i18n } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(
    supportedLang.indexOf(i18n.language) || 0
  );

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpened(!isOpened);
  };

  useClickAway(dropdownRef, () => {
    setIsOpened(false);
  });

  const changeLang = (index: number) => {
    i18n.changeLanguage(supportedLang[index]);
    setSelectedLanguage(index);
    dayjs.locale(supportedLang[index] === "no" ? "nb" : supportedLang[index]);
    setIsOpened(false);
  };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div className={styles.dropdown__top} onClick={toggleDropdown}>
        <div className={styles.dropdown__icon}>
          <img src={languageImages[languages[selectedLanguage]]} alt="" />
        </div>
        <div className={styles.dropdown__title}>
          {t(languages[selectedLanguage])}
        </div>
        <ArrowSVG
          className={cn(styles.dropdown__arrow, {
            [styles.opened]: isOpened,
          })}
        />
      </div>
      {isOpened && (
        <div className={styles.dropdown__bottom}>
          {languages.map((item, index) => {
            return (
              <div
                className={styles.list__item}
                key={item}
                onClick={() => changeLang(index)}
              >
                <img src={languageImages[item]} alt="" />
                {t(item)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropDown;
