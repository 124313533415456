import React from "react";
import { useTranslation } from "react-i18next";

import type { AttendeeType } from "constants/models";

import Attender from "components/Attender";

import { ReactComponent as PeopleSVG } from "assets/images/people.svg";

import styles from "./index.module.scss";

interface AttendeesProps {
  attendees: AttendeeType[];
  isEventSpecial: boolean;
}

const Attendees = ({ attendees, isEventSpecial }: AttendeesProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.attendees}>
      <div className={styles.title}>
        <PeopleSVG />
        {isEventSpecial ? t("organizers") : t("attendees")}
      </div>
      <div className={styles.attendees__list}>
        <ul className={styles.list}>
          {attendees.map((item) => {
            return (
              <Attender
                key={item.email}
                attender={item}
                isEventSpecial={isEventSpecial}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Attendees;
