import { getWeekdayLocaleIndex } from "./getWeekdayLocaleIndex";
import type { Locale } from "date-fns";

type Weekday = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";

/**
 * Tests if weekdays array are in order and without spaces
 *  @example
 *  ['Mon', 'Wed', 'Fri'] -> false
 *  ['Mon', 'Wed', 'Tue'] -> false
 *  ['Mon', 'Tue', 'Wed'] -> true
 */
export function isWeekdaysInConsecutiveOrder(
  weekdays: Weekday[],
  locale?: Locale
): boolean {
  return weekdays.every((weekday, index) => {
    if (index === 0) {
      return true;
    }

    const weekdayIndex = getWeekdayLocaleIndex(weekday, locale);
    const prevWeekdayIndex = getWeekdayLocaleIndex(weekdays[index - 1], locale);

    return weekdayIndex - prevWeekdayIndex === 1;
  });
}
