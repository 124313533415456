import React from "react";
import { useTranslation } from "react-i18next";

import type { AttendeeType } from "constants/models";

import ResponseStatus from "components/Attender/ResponseStatus";

import { ReactComponent as UserSVG } from "assets/images/user.svg";

import styles from "./index.module.scss";

interface AttenderProps {
  attender: AttendeeType;
  isEventSpecial: boolean;
}

const Attender = ({ attender, isEventSpecial }: AttenderProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.attender}>
      <div className={styles.attender__left}>
        {attender.img ? (
          <img src={attender.img} alt='' className={styles.attender__pic} />
        ) : (
          <UserSVG />
        )}
      </div>
      <div className={styles.attender__right}>
        <div className={styles.right__top}>
          <div
            className={styles.attender__name}
            dangerouslySetInnerHTML={{ __html: attender.displayName }}
          />
          {attender.type === 0 && (
            <div className={styles.attender__organizer}>{t("organizer")}</div>
          )}
        </div>
        <div className={styles.attender__email}>{attender.email}</div>
        {!isEventSpecial && <ResponseStatus status={attender.responseStatus} />}
      </div>
    </div>
  );
};

export default Attender;
