import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DropDown from "components/DropDown";
import ReCAPTCHA from "react-google-recaptcha";

import BGImage from "assets/images/recaptcha_bg.jpg";
import BGImageMobile from "assets/images/recaptcha_bg_mobile.jpg";

import { request } from "api/APIService";

import { PATHS } from "constants/constants";

import styles from "./index.module.scss";

const Recaptcha = () => {
  const sitekey = "6LeanccZAAAAAPIftWnlgw8wFTiIObzhieNk8ea5";
  const { t } = useTranslation();

  const isMobile = window.innerWidth < 800;

  const navigate = useNavigate();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const key = params.get("key");
  const mode = params.get("mode");

  const register = async (token: string | null) => {
    try {
      const response = await request({
        scope: "reCaptcha",
        value: token,
      });
      if (response.data.success) {
        navigate(`${PATHS.index}?mode=${mode}&key=${key}`, {
          state: { mode, key },
        });
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <div className={styles.recaptcha__container}>
      <div className={styles.container__top}>
        <img src={isMobile ? BGImageMobile : BGImage} alt="" />
      </div>
      <div className={styles.container__bottom}>
        <div className={styles.language__picker}>
          <DropDown />
        </div>
        <div className={styles.container__inner}>
          <h1 className={styles.recaptcha__title}>
            {t("verify-you-are-not-robot")}
          </h1>
          <ReCAPTCHA sitekey={sitekey} onChange={register} />
        </div>
        <div className={styles.policy}>
          Powered by RecMan -
          <a
            href="https://career.recman.no/policy.php?type=recman"
            target="_blank"
          >
            Terms of service
          </a>
        </div>
      </div>
    </div>
  );
};

export default Recaptcha;
