import React from "react";
import cn from "classnames";

import styles from "./index.module.scss";

interface ITooltip {
  classname?: string;
  text: string;
}

const Tooltip: React.FC<ITooltip> = ({ text, classname }) => {
  return (
    <div className={cn(styles.wrapper, classname)}>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default Tooltip;
