import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { ReactComponent as LinkSVG } from "assets/images/link.svg";
import { ReactComponent as GoogleMeetSVG } from "assets/images/google-meet.svg";
import { ReactComponent as MicrosoftSVG } from "assets/images/microsoft.svg";
import { ReactComponent as CopySVG } from "assets/images/copy.svg";
import { ReactComponent as CheckSVG } from "assets/images/checkmark.svg";

import styles from "./index.module.scss";

interface LinkButtonsProps {
  conferencingType: number;
  conferencingUrl?: string;
}

const LinkButtons = ({
  conferencingType,
  conferencingUrl,
}: LinkButtonsProps) => {
  const { t } = useTranslation();

  const [copiedState, setCopiedState] = useState(false);

  const isMobile = window.innerWidth < 580;
  const isButtonsShowed = conferencingType !== 0 && !!conferencingUrl;

  const copyUrlHandler = () => {
    if (!conferencingUrl) return;
    if (!isMobile) {
      navigator.clipboard
        .writeText(conferencingUrl)
        .then(() => {
          setCopiedState(true);
          setTimeout(() => setCopiedState(false), 2000);
        })
        .catch(() => {
          console.log("Can't copy");
        });
    } else {
      const data = {
        url: conferencingUrl,
      };
      if (navigator.share) {
        navigator
          .share(data)
          .then((succ: any) => {
            if (succ) {
              console.log("success");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return isButtonsShowed ? (
    <div className={styles.links__group}>
      <div className={styles.links__title}>
        <LinkSVG />
        {t("video-meeting")}
      </div>
      <div className={styles.links__body}>
        {conferencingType === 1 && (
          <a href={conferencingUrl} className={styles.btn} target='_blank'>
            <GoogleMeetSVG />
            <span>{t("join-with")} Google Meet</span>
          </a>
        )}
        {conferencingType === 2 && (
          <a href={conferencingUrl} className={styles.btn} target='_blank'>
            <MicrosoftSVG />
            {t("join-with")} Microsoft Teams
          </a>
        )}

        {!copiedState ? (
          <button
            className={cn(styles.btn, styles.btn__copy)}
            onClick={copyUrlHandler}
          >
            <CopySVG />
            <span>{t("copy-link")}</span>
          </button>
        ) : (
          <button className={cn(styles.btn)}>
            <CheckSVG className={styles.icon__copied} />
            <span>{t("copied")}</span>
          </button>
        )}
      </div>
      <span className={styles.links__value}>{conferencingUrl}</span>
    </div>
  ) : null;
};

export default LinkButtons;
