import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as NoTimeSVG } from "assets/images/notime.svg";

import styles from "./index.module.scss";

const NoAlternatives = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.notime__available}>
      <NoTimeSVG />
      <div className={styles.notime__title}>
        {t("no-available-alternatives")}
      </div>
    </div>
  );
};

export default NoAlternatives;
