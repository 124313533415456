import React, { Children } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  green?: boolean;
  red?: boolean;
  orange?: boolean;
  gray?: boolean;
}

const Button = ({
  green,
  children,
  onClick,
  red,
  orange,
  gray,
  className,
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        styles.btn,
        {
          [styles.green]: green,
          [styles.red]: red,
          [styles.orange]: orange,
          [styles.gray]: gray,
        },
        className
      )}
    >
      {children}
    </button>
  );
};

export default Button;
