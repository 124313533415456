import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as MaybeSVG } from "assets/images/maybe.svg";
import { ReactComponent as GoingSVG } from "assets/images/going.svg";
import { ReactComponent as CrossSVG } from "assets/images/cross.svg";
import { ReactComponent as WaitingSVG } from "assets/images/waiting.svg";

import styles from "./index.module.scss";

interface ResponseStatusProps {
  status: number;
}

const ResponseStatus = ({ status }: ResponseStatusProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.attender__status}>
      {status === 3 && (
        <div className={styles.attender__going}>
          <GoingSVG />
          <span>{t("going")}</span>
        </div>
      )}
      {status === 0 && (
        <div className={styles.attender__waiting}>
          <WaitingSVG />
          <span>{t("waiting-response")}</span>
        </div>
      )}
      {status === 1 && (
        <div className={styles.attender__notgoing}>
          <CrossSVG />
          <span>{t("not-going")}</span>
        </div>
      )}
      {status === 2 && (
        <div className={styles.attender__maybe}>
          <MaybeSVG />
          <span>{t("maybe")}</span>
        </div>
      )}
    </div>
  );
};

export default ResponseStatus;
