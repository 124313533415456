import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import Button from "components/Button";

import { ReactComponent as RegisrationSVG } from "assets/images/registration.svg";

import styles from "./index.module.scss";

interface RegisterFormProps {
  confirmClick: (userDate: any, isRegister?: boolean) => Promise<void>;
  attendeeIsExist: boolean;
}

const RegisterForm = ({ confirmClick, attendeeIsExist }: RegisterFormProps) => {
  const { t } = useTranslation();

  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [commentInput, setCommentInput] = useState("");

  const [validationErrorName, setValidationErrorName] = useState(false);
  const [validationErrorEmail, setValidationErrorEmail] = useState(false);

  const registerClick = () => {
    const isValidationErrorName = nameInput.trim() === "";
    const isValidationErrorEmail = !/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/.test(
      emailInput
    );

    setValidationErrorName(isValidationErrorName);
    setValidationErrorEmail(isValidationErrorEmail);

    if (isValidationErrorName || isValidationErrorEmail) {
      console.log("error");
    } else {
      confirmClick(
        {
          name: nameInput,
          email: emailInput,
          comment: commentInput,
        },
        true
      );
    }
  };

  return (
    <div className={styles.form}>
      <div className={styles.form__title}>
        <RegisrationSVG />
        {t("registration")}
      </div>
      <div className={styles.form__body}>
        <div className={styles.form__body_top}>
          <div className={styles.form__group}>
            <label htmlFor='name' className={styles.label}>
              {t("name")} <span>*</span>{" "}
            </label>
            <input
              type='text'
              id='name'
              autoComplete='off'
              value={nameInput}
              onChange={(e) => setNameInput(e.target.value)}
              className={cn(styles.form__input, {
                [styles.validation__error]: validationErrorName,
              })}
            />
            <div
              className={cn(styles.error, {
                [styles.show]: validationErrorName,
              })}
            >
              {t("enter-name")}
            </div>
          </div>
          <div className={styles.form__group}>
            <label htmlFor='email' className={styles.label}>
              {t("email")}
              <span> *</span>
            </label>
            <input
              type='email'
              value={emailInput}
              autoComplete='off'
              onChange={(e) => setEmailInput(e.target.value)}
              className={cn(styles.form__input, {
                [styles.validation__error]:
                  validationErrorEmail || attendeeIsExist,
              })}
              id='email'
            />
            <div
              className={cn(styles.error, {
                [styles.show]: validationErrorEmail,
              })}
            >
              {t("enter-email")}
            </div>
            <div
              className={cn(styles.error, {
                [styles.show]: attendeeIsExist,
              })}
            >
              {t("email-is-registered")}
            </div>
          </div>
        </div>
        <div className={styles.form__body_bottom}>
          <label htmlFor='comment' className={styles.label}>
            {t("comment")}
          </label>
          <textarea
            className={styles.form__textarea}
            id='comment'
            value={commentInput}
            onChange={(e) => setCommentInput(e.target.value)}
          ></textarea>
        </div>
        <div className={styles.form__btn_wrap}>
          <Button green onClick={registerClick}>
            {t("register")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
