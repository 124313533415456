import { parse, format, type Locale } from "date-fns";

type Weekday = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";

/**
 * Returns a localized index of weekday
 * @example
 * 'Mon' -> 1
 * 'Sun' -> 7
 * 'Sun' -> 1 in locale where week starts from Sunday
 */
export function getWeekdayLocaleIndex(
  weekday: Weekday,
  locale?: Locale
): number {
  const approxDate = parse(weekday, "E", new Date());
  const approxIndex = +format(approxDate, "e", { locale });

  return approxIndex;
}
