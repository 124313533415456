import english from "assets/images/flags/uk.png";
import danish from "assets/images/flags/denmark.png";
import norwegian from "assets/images/flags/norway.png";
import swedish from "assets/images/flags/sweden.png";
import spanish from "assets/images/flags/spain.png";

export const languageImages: Record<string, string> = {
  english,
  norwegian,
  swedish,
  danish,
  spanish,
};

export const languages = [
  "english",
  "norwegian",
  "swedish",
  "danish",
  "spanish",
];

export const supportedLang = ["en", "no", "sv", "da", "es"];
