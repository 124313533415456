import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as LocationSVG } from "assets/images/location.svg";
import { ReactComponent as PlanetSVG } from "assets/images/planet.svg";

import styles from "./index.module.scss";

interface MapProps {
  url?: { url: string; label: string };
  address: string;
}

const Map = ({ url, address }: MapProps) => {
  const { t } = useTranslation();

  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mapRef.current) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          initMap(location);
        } else {
          console.log(status);
        }
      });
    }
  }, []);

  const initMap = (location: google.maps.LatLng) => {
    const map = new window.google.maps.Map(mapRef.current!, {
      center: { lat: -34.397, lng: 140.644 },
      zoomControl: false,
      zoom: 8,
      gestureHandling: "cooperative",
      disableDefaultUI: true,
    });

    map.setCenter(location);

    new window.google.maps.Marker({
      position: location,
      map: map,
    });
  };

  return (
    <div className={styles.map}>
      <div className={styles.title}>
        <LocationSVG />
        {t("location")}
      </div>
      <div className={styles.address}>{address}</div>
      <div className={styles.google__map} ref={mapRef}></div>
      {url && (
        <>
          <div className={styles.title}>
            <PlanetSVG />
            {t("link")}
          </div>
          <a href={url?.url} className={styles.link} target='_blank'>
            {url?.label}
          </a>
        </>
      )}
    </div>
  );
};

export default Map;
