import React, { useLayoutEffect, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "components/Header";
import Confirmed from "components/Confirmed";
import LinkButtons from "components/LinkButtons";
import Map from "components/Map";
import Attendees from "components/Attendees";
import SelectEvent from "components/SelectEvent";
import DateAndTime from "components/DateAndTime";

import { ReactComponent as NotesSVG } from "assets/images/notes.svg";

import { ErrorType, EventDataType } from "constants/models";
import { MODES, SCOPES } from "constants/constants";

import { request } from "api/APIService";

import styles from "./index.module.scss";

const Main = () => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const navigate = useNavigate();

  const [eventData, setEventData] = useState<EventDataType | null>(null);

  const params = new URLSearchParams(search);
  const key = params.get("key");
  const mode = params.get("mode");

  const redirectToRecaptcha = () => {
    navigate(`/recaptcha?mode=${mode}&key=${key}`, {
      state: { mode, key },
    });
  };

  const redirectToNotFound = () => {
    navigate(`/404?key=${key}`);
  };

  const fetchData = async () => {
    if (mode !== MODES.invite && mode !== MODES.event) {
      redirectToNotFound();
    }

    if (mode === MODES.event) {
      const data = {
        attendeeToken: key,
        scope: SCOPES.fetchEvent,
      };

      try {
        const response = await request(data);
        if (!response.data.success) {
          if (response.data.errors.some((obj: ErrorType) => obj.code === 5)) {
            redirectToRecaptcha();
          }
          if (response.data.errors.some((obj: ErrorType) => obj.code === 7)) {
            redirectToNotFound();
          }
        }
        document.title = response.data.data.event.title;
        setEventData(response.data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    } else {
      const data = {
        token: key,
        scope: SCOPES.fetchAppointmentEvent,
      };
      try {
        const response = await request(data);

        if (!response.data.success) {
          if (response.data.errors.some((obj: ErrorType) => obj.code === 5)) {
            redirectToRecaptcha();
          }
          if (response.data.errors.some((obj: ErrorType) => obj.code === 7)) {
            redirectToNotFound();
          }
        }
        document.title = response.data.data.event.title;
        setEventData(response.data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main__content}>
      {!!eventData?.success && (
        <>
          <Header
            title={eventData.data.event.title}
            logo={eventData.data.corporation?.logo}
          />
          {eventData.data.selectedTimeslot && (
            <Confirmed
              eventTitle={eventData.data.event.title}
              eventConfirmDate={eventData.data.selectedTimeslot}
            />
          )}

          {mode === MODES.invite &&
            !eventData.data.selectedTimeslot &&
            !!eventData.data.event.alternatives && (
              <SelectEvent
                alternatives={eventData.data.event.alternatives}
                eventTimezone={eventData.data.event.timeZone}
                eventAttendee={eventData.data.eventAttendee}
                eventKey={key || ""}
                fetchData={fetchData}
                eventDuration={eventData.data.event.alternatives[0]?.duration}
                setEventData={setEventData}
              />
            )}

          {mode === MODES.event && (
            <DateAndTime
              start={eventData.data.event.start}
              end={eventData.data.event.end}
              timeZone={eventData.data.event.timeZone}
              currentResponse={eventData.data.event.currentUserResponseStatus}
              recurrence={eventData.data.event.recurrence}
              recurrenceOptions={eventData.data.event?.recurrenceOptions}
              eventKey={key || ""}
              fetchData={fetchData}
            />
          )}
          <div className={styles.content__wrap}>
            {eventData.data.event.notes && (
              <div className={styles.description__wrap}>
                <div className={styles.description__title}>
                  <NotesSVG />
                  {t("description")}
                </div>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: eventData.data.event.notes,
                  }}
                ></div>
              </div>
            )}
            <LinkButtons
              conferencingType={eventData.data.event.conferencingType}
              conferencingUrl={eventData.data.event.conferencingUrl}
            />
            {eventData.data.event.location && (
              <Map
                url={eventData.data.event?.eventUrl}
                address={eventData.data.event.location}
              />
            )}
            <Attendees
              attendees={eventData.data.event.attendees}
              isEventSpecial={!!eventData.data.event?.alternatives?.length}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Main;
