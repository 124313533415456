export enum SCOPES {
  fetchEvent = "fetchEvent",
  fetchAppointmentEvent = "fetchAppointmentEvent",
  enterEventDecision = "enterEventDecision",
  enterAppointmentEventDecision = "enterAppointmentEventDecision",
}

export enum PATHS {
  index = "/",
  recaptcha = "recaptcha",
}

export enum MODES {
  invite = "invite",
  event = "event",
}

export enum RecurrenceTextDays {
  Mon = "Monday",
  Tue = "Tuesday",
  Wed = "Wednesday",
  Thu = "Thursday",
  Fri = "Friday",
  Sat = "Saturday",
  Sun = "Sunday",
}

export enum UserAnswers {
  No = 1,
  Maybe = 2,
  Yes = 3,
}

export const viewTypes = [
  {
    img: "View1SVG",
    title: "List",
  },
  {
    img: "View2SVG",
    title: "Week",
  },
  {
    img: "View3SVG",
    title: "Month",
  },
];
